import React from "react";
import { Routes, Route, useLocation, useParams } from "react-router";
// Constants
import { ROUTE } from "../../../constants";

/**
 * Components
 */
import {
  asyncComponent,
  HeaderBar,
  CopyrightFooter,
} from "../../../components";

/**
 * Pages
 */
import SSOSignUp from "../../../pages/SSOSignUp";
import SSOSignUpPending from "../../../pages/SSOSignUpPending";

// Styles
import classes from "./index.module.css";

const AsyncNotFoundPage = asyncComponent(() =>
  import("../../../pages/NotFoundPage")
);

const SSOContainer = ({ children }) => {
  const { memid } = useParams();

  return (
    <>
      <HeaderBar memid={memid} />
      <div className={classes.container}>{children}</div>
      <CopyrightFooter />
    </>
  );
};

const SSOSignUpRoutes = ({ pathname, email }) => {
  return (
    <>
      <Route path={ROUTE.MODULE_ROUTE.Signup.Root}>
        <Route
          index={true}
          element={
            <SSOContainer pathname={pathname}>
              <SSOSignUp />
            </SSOContainer>
          }
        />
        {email && (
          <Route
            path={ROUTE.MODULE_ROUTE.Signup.Pending}
            element={
              <SSOContainer pathname={pathname}>
                <SSOSignUpPending email={email} />
              </SSOContainer>
            }
          />
        )}
      </Route>
    </>
  );
};

const SSORoutes = () => {
  const location = useLocation();

  const { pathname = "", state = {} } = location;

  const email = state && state.email;

  return (
    <>
      <Routes>
        <Route path={ROUTE.MODULE_ROUTE.Referral}>
          <Route path={ROUTE.MODULE_ROUTE.Referral_Membership}>
            {SSOSignUpRoutes({ pathname, email })}
          </Route>
        </Route>

        {SSOSignUpRoutes({ pathname, email })}

        <Route
          path="*"
          element={
            <SSOContainer pathname={pathname}>
              <AsyncNotFoundPage />
            </SSOContainer>
          }
        />
      </Routes>
    </>
  );
};

export default SSORoutes;
