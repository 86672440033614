import classes from "./index.module.css";

const SSOSignUpPending = ({email = ""}) => {
	return (
		<>
			<h4 className={classes.title}>
				<strong>Verify Your Email</strong>
			</h4>
			<p className={classes.description}>
				You’re almost there! We’ve sent an verification email to {email}.
				&nbsp;Go to your email and click on the link to complete the sign up.
			</p>
		</>
	);
};

export default SSOSignUpPending;
