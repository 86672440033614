export const MODULE_ROUTE = {
  // App Info
  'Root_Page' : '*',
  'Landing_Page' : '*',
  'Referral': 'referral',
  'Referral_Membership': ':memid',
  'Signup': {
    'Root': 'signup',
    'Pending': 'pending',
  }
}