import logoColor from "../../../../assets/logo/logo_title_color.svg";

// Config
import * as CONFIG from '../../config'

// Constant

// Styles
import classes from "./index.module.css";

const HeaderBar = ({memid}) => {

	return (
		<div className={classes["bordered"]}>
			<div className={classes["navbar"]}>
				<span className={classes.leftHeader}>
					<img src={logoColor} className={classes["icon-img"]} alt="" />
					<p className={classes.version}>V {CONFIG.APP_INFO.APP_VERSION}</p>
				</span>
				<p>{memid}</p>
			</div>
		</div>
	);
};

export default HeaderBar;
