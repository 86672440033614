import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Form, Input,Row,Col, Button, Select, message, Checkbox } from "antd";

import { NumericInput } from "../../components";

// constant
import { ROUTE as ROUTE_CONSTANT } from "../../constants";

// redux actions
import * as ACTIONS from "../../services/redux/actions";

// Style
import classStyles from "./index.module.css";
const { TextArea } = Input
const SSOSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { memid } = useParams();

  const [loadingSignup, setLoadingSignup] = useState(false);
  const [input_number, setInput_number] = useState("");
  const [ isBzAcc, setIsBzAcc ] = useState(false)
  const [ company, setCompany ] = useState({
    name: "",
    address:'',
    postcode: "",
    city: "",
    state: "",
  })
  console.log(company.state)
  const options = [
    { value: "Johor", label: "Johor" },
    { value: "Kedah", label: "Kedah" },
    { value: "Melaka", label: "Melaka" },
    { value: "Negeri Sembilan", label: "Negeri Sembilan" },
    { value: "Pahang", label: "Pahang" },
    { value: "Penang", label: "Penang" },
    { value: "Peral", label: "Peral" },
    { value: "Perlis", label: "Perlis" },
    { value: "Sabah", label: "Sabah" },
    { value: "Sarawak", label: "Sarawak" },
    { value: "Selangor", label: "Selangor" },
    { value: "Trengganu", label: "Trengganu" },
    { value: "WP Labuan", label: "WP Labuan" },
    { value: "WP Putrajaya", label: "WP Putrajaya" },
    { value: "WP Kuala Lumpur", label: "WP Kuala Lumpur" },
]

  const handleBzInfo =(info)=>{
    const {name, value} = info.target;
    setCompany({
        ...company,
        [name]: value
    })
}

  const onFinish = (e) => {
    const { contact, email, name, password, contactPrefix } = e;

    if (name && password && contact && email && contactPrefix) {
      dispatch(
        ACTIONS.main_user.sso_user_sign_up_request(
          {
            email,
            name,
            password,
            contact: `${contactPrefix}-${contact}`,
            cusamid: memid,
            company
          },
          ({ status, err }) => {
            if (status === 200) {
              message.success("Sign Up Successfully.");
              navigate(ROUTE_CONSTANT.MODULE_ROUTE.Signup.Pending, {
                state: { email },
              });
              setLoadingSignup(false);
            } else if (status === 404) {
              message.error(err);
              setLoadingSignup(false);
            }
          }
        )
      );
    }

    setLoadingSignup(true);
  };

  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

  const validateMessages = {
    required: "${name} is required!",
  };

  return (
    <>
      <h4 className={classStyles.title}>
        <strong>Sign Up</strong>
      </h4>
      <p>For Everyone Anywhere Anytime</p>

      <div>
        <div className={classStyles.input}>
          <Form
            form={form}
            name="form_signup"
            layout="vertical"
            validateMessages={validateMessages}
            initialValues={{
              contactPrefix: "+60",
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label={<span>Name</span>}
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
              hasFeedback
            >
              <Input className={classStyles.inputField} />
            </Form.Item>
            <Form.Item
              name="email"
              label={<span>Email</span>}
              rules={[
                {
                  required: true,
                  message: "Email is required",
                  type: "email",
                },
              ]}
              hasFeedback
            >
              <Input className={classStyles.inputField} />
            </Form.Item>
            <Form.Item
              name="contact"
              label={<span>Contact</span>}
              rules={[
                {
                  required: true,
                  message: "Contact is required",
                },
              ]}
              hasFeedback
            >
              <NumericInput
                value={input_number}
                size="large"
                onChange={setInput_number}
                addonBefore={prefixContactSelector}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={<span className={classStyles.inputLabel}>Password</span>}
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
              hasFeedback
            >
              <Input.Password className={classStyles.inputPassword} />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              label={
                <span className={classStyles.inputLabel}>Confirm Password</span>
              }
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
              hasFeedback
            >
              <Input.Password className={classStyles.inputPassword} />
            </Form.Item>
            <Form.Item>
            <Checkbox checked={isBzAcc} name="bzAccCheck" 
                onChange={() => setIsBzAcc(!isBzAcc)}
                    >
                    Sign up as business account
                </Checkbox>
            </Form.Item>

            {isBzAcc ? <> <Row>
                <Col span={24}>
                    <Form.Item 
                        // name="compName" 
                        label={<span className={classStyles.inputLabel}><span style={{color:'red'}}>* </span>Company Name</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Company Name is required',
                            },
                        ]}
                    >
                        <Input className={classStyles.inputField} name="name" value={company.name} onChange={handleBzInfo} required/>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item 
                        label={<span className={classStyles.inputLabel}>Company Address</span>}
                    >
                        <TextArea name="address" value={company.address} rows={4} onChange={handleBzInfo}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                    <Form.Item  
                        label={<span className={classStyles.inputLabel}>Postcode</span>}
                        hasFeedback
                    >
                        <Input style={{padding:'2%'}} name="postcode" value={company.postcode} onChange={handleBzInfo} placeholder="  Eg: 43300"/>
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                    <Form.Item
                        label={<span className={classStyles.inputLabel}>City</span>}
                    >
                        <Input style={{padding:'2%'}} name="city" value={company.city} onChange={handleBzInfo} placeholder="  Eg: Kuala Lumpur"/>
                    </Form.Item>
                </Col>
                <Col span={window.innerWidth >= 1000 ? 12 : 24}>
                    <Form.Item
                        label={<span className={classStyles.inputLabel}>State</span>}
                    >
                        <Select
                            name="state"
                            // value={company.state}
                            onChange={(value) => {
                                setCompany((prev) => ({...prev, state: value }));
                            }}
                            placeholder='-- SELECT --'
                            // options={[
                            //     { value: "Johor", label: "Johor" },
                            //     { value: "Kedah", label: "Kedah" },
                            //     { value: "Melaka", label: "Melaka" },
                            //     { value: "Negeri Sembilan", label: "Negeri Sembilan" },
                            //     { value: "Pahang", label: "Pahang" },
                            //     { value: "Penang", label: "Penang" },
                            //     { value: "Peral", label: "Peral" },
                            //     { value: "Perlis", label: "Perlis" },
                            //     { value: "Sabah", label: "Sabah" },
                            //     { value: "Sarawak", label: "Sarawak" },
                            //     { value: "Selangor", label: "Selangor" },
                            //     { value: "Trengganu", label: "Trengganu" },
                            //     { value: "WP Labuan", label: "WP Labuan" },
                            //     { value: "WP Putrajaya", label: "WP Putrajaya" },
                            //     { value: "WP Kuala Lumpur", label: "WP Kuala Lumpur" },
                            // ]}
                            >
                              {options.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                  {option.label}
                                </Select.Option>
                              ))}
                          </Select>
                    </Form.Item>
                </Col>
            </Row></> : null}
            <Checkbox style={{ color: "var(--greyColor)" }}>
              By clicking “sign up”, you confirm that you have read and accept
              the Terms of Services and Privacy Policy.
            </Checkbox>
            <Form.Item>
              <Button
                className={classStyles.inputButton}
                type="primary"
                htmlType="submit"
                loading={loadingSignup}
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SSOSignUp;
